export const SET_LANGUAGE = "SET_LANGUAGE";
export const FETCH_USER = "FETCH_USER";
export const PRELIMINARY_INFO = "PRELIMINARY_INFO";
export const EDIT_SECTIONS = "EDIT_SECTIONS";
export const EDIT_GROUPS = "EDIT_GROUPS";
export const RESET_COURSE = "RESET_COURSE";
export const FETCH_CART_ITEMS = "GET_CART_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const FETCH_COURSE_DATA_SUCCESS = "FETCH_COURSE_DATA_SUCCESS";

