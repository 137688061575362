import {
  grayColor,
  whiteColor,
  primaryColor,
  secondaryColor,
  dangerColor,
  mlAuto,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import tooltip from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const headerLinksStyle = (theme) => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      // "&:not(:last-child)": {
      //   "&:after": {
      //     width: "calc(100% - 30px)",
      //     content: '""',
      //     display: "block",
      //     height: "1px",
      //     marginLeft: "15px",
      //     backgroundColor: grayColor[14],
      //   },
      // },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  navLinkJustIcon: {
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      marginRight: "0px",
    },
    "& svg": {
      marginRight: "0px",
    },
  },
  profileIcon: {
    width: "30px !important",
    height: "30px !important",
    marginLeft: "5px",
  },
  profileButton: {
    position: "relative",
    padding: "6px 15px",
    paddingRight: "5px",
    // fontWeight: "bold",
    fontSize: "0.75rem",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
  },
  navButton: {
    position: "relative",
    // padding: "8px 20px",
    // fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
  },
  navNotification: {
    position: "relative",
    padding: "6px",
    borderRadius: "50%",
    fontSize: "12px",
    textTransform: "uppercase",
    backgroundColor: whiteColor,
    marginLeft: "10px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      height: 45,
      width: "100%",
      margin: "0 !important",
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
      borderRadius: 50,
      borderWidth: 0.5,
      borderColor: secondaryColor[0],
      borderStyle: "solid",
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    },
  },
  notificationIcon: {
    color: primaryColor[0],
    margin: "0px",
    width: "22px",
    height: "22px",
  },
  navLangButton: {
    position: "relative",
    backgroundColor: whiteColor,
    color: secondaryColor[0],
    borderRadius: 150,
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginLeft: "1rem",
    // fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
      marginLeft: 0,
      borderWidth: 0.5,
      borderColor: secondaryColor,
      borderStyle: "solid",
    },
    "& $icons": {
      marginRight: "3px",
    },
    "&, &:focus,&:active ": {
      color: secondaryColor[0],
      backgroundColor: whiteColor,
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
      color: secondaryColor[0],
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  registerNavLink: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "inherit",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)",
    },
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px",
  },
  languageIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginBottom: 5,
    marginTop: 3,
  },
  teacherIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
    marginBottom: 5,
    marginTop: 3,
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  socialIcons: {
    position: "relative",
    fontSize: "1.25rem",
    maxWidth: "24px",
  },
  headerLink: {
    fontSize: "0.75rem",
    // fontWeight: "bold",
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    "&:hover,&:focus": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
    },
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      padding: 9,
      justifyContent: "center",
      marginBottom: "5px",
      marginTop: "5px",
      marginLeft: "0 !important",
      textAlign: "center",
      fontSize: "0.75rem",
      backgroundColor: whiteColor,
      borderRadius: 50,
      borderWidth: 0.5,
      borderColor: secondaryColor[0],
      borderStyle: "solid",
      color: secondaryColor[0],
      "&:hover,&:focus": {
        color: "inherit",
      },
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  mlAuto,
  notificationList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  notificationItem: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  notificationUnread: {
    backgroundColor: secondaryColor[1],
    margin: "0px -10px",
    padding: "0px 10px",
  },
  notificationBody: {
    margin: "0px",
    fontSize: "0.75rem",
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  notificationDate: {
    margin: "0px",
    fontSize: "0.65rem",
  },
  dropdownItemStyle: {
    minWidth: "250px",
  },
  notificationDot: {
    background: primaryColor[0],
    height: "7.5px",
    width: "7.5px",
    borderRadius: "50%",
    marginLeft: "17px",
    marginRight: "5px",
    position: "absolute",
    left: "85%",
  },
  noNotifications: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  cartIcon: {
    width: "30px !important",
    height: "30px !important",
    marginRight: "0px !important",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      color: secondaryColor[0],
    },
  },
  cartButton: {
    position: "relative",
    padding: "6px",
    marginLeft: "10px !important",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    display: "inline-flex",
    backgroundColor: secondaryColor[0],

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "5px",
      marginTop: "5px",
      marginLeft: "0 !important",
      textAlign: "left",
      fontSize: "0.75rem",
      backgroundColor: whiteColor,

      borderRadius: 50,
      borderWidth: 0.5,
      borderColor: secondaryColor[0],
      borderStyle: "solid",

      "& > span:first-child": {
        justifyContent: "center",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    },
  },
  cartLabel: {
    display: "none",
    color: secondaryColor[0],
    marginRight: 15,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  cartItems: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "6px",
      border: "1px solid " + primaryColor[0],
      right: "4px",
      fontSize: "9px",
      background: primaryColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      verticalAlign: "middle",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      marginRight: "8px",
      position: "absolute",
      top: "4px",
      border: "1px solid " + primaryColor[0],
      right: "70px",
      background: primaryColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      verticalAlign: "middle",
      display: "block",
    },
  },
});

export default headerLinksStyle;
