

const instructorsList = {
    rowContainer: {
        marginTop: 30,
        marginBottom: 20
      },
};

export default instructorsList;
