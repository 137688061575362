const phoneInputStyle = {
  inputAdornment: {
    position: "relative",
  },
  codeSelect: {
    borderRadius: 0,
    borderTop: 0,
    borderBottom: 0,
    paddingLeft: 5,
  },
};

export default phoneInputStyle;
